import React from "react";
import { LayoutTemplate } from "../LayoutTemplate";
import * as S from "./styles";
import { Button } from "../../Atoms/Button";
import { useMediaQuery } from "react-responsive";
import CookieBannerPage from "../../Atoms/CookieBanner";

export const HomeTemplate = () => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });

  function handleScheduling() {
    window.open("agendamento", "_self");
  }

  return (
    <LayoutTemplate>
      <CookieBannerPage />
      <S.Container>
        <S.Main id="sobre">
          <S.Title>
            <img src="/assets/imgs/group-title.svg" alt="img context" />
            Velox vistorias
          </S.Title>
          {isMobile ? (
            <S.Text>
              Somos uma empresa dedicada a fornecer serviços de{" "}
              <S.Strong>vistorias automotivas precisas</S.Strong> e{" "}
              <S.Strong>confiáveis</S.Strong>, com uma{" "}
              <S.Strong>equipe experiente</S.Strong> e altamente{" "}
              <S.Strong>qualificada</S.Strong>.
            </S.Text>
          ) : (
            <S.Text>
              Somos uma empresa dedicada a fornecer serviços de{" "}
              <S.Strong>vistorias automotivas precisas</S.Strong> e{" "}
              <S.Strong>confiáveis</S.Strong>, com uma{" "}
              <S.Strong>equipe experiente</S.Strong> e altamente{" "}
              <S.Strong>qualificada</S.Strong>. Nos destacamos pelo compromisso
              com a segurança dos nossos clientes.
            </S.Text>
          )}
          <Button onClick={handleScheduling}>AGENDE SUA VISTORIA</Button>
        </S.Main>
        <S.SectionService id="servicos">
          <S.ServiceContent>
            <h2>
              <img src="/assets/imgs/group-dark.svg" alt="icone group" />
              SERVIÇOS
            </h2>
            <p>
              <S.Strong>Compre</S.Strong> e <S.Strong>agende online</S.Strong>{" "}
              sua vistoria conosco.
            </p>

            <S.ServiceInfos>
              <h2>Vistoria de Transferência / 1° EMPLACAMENTO Posto fixo</h2>
              <S.Wrapper>
                <div>
                  <h3 id="FirtItem">Carro / Moto </h3>
                </div>
                <div>
                  <h4>
                    R$ 152<span>**</span>
                  </h4>
                </div>
              </S.Wrapper>
              <S.Wrapper>
                <div>
                  <h3>
                    Veículos Grande Porte<span>*</span>
                  </h3>
                </div>

                <div>
                  <h4>
                    R$ 190<span>**</span>
                  </h4>
                </div>
              </S.Wrapper>

              <h2>Vistoria de Transferência / 1° EMPLACAMENTO Domicilar</h2>
              <S.Wrapper>
                <div>
                  <h4>
                    R$ 190<span>**</span>
                  </h4>
                </div>
              </S.Wrapper>

              <S.Info>
                <p>
                  <span className="strong">*</span>
                  Ônibus <span>/</span> Micro-ônibus <span>/</span> Reboque{" "}
                  <span>/</span> Semirreboque <span>/</span> Caminhão{" "}
                  <span>/</span> Caminhão Trator <span>/</span> Trator de Rodas{" "}
                  <span>/</span> Trator Misto <span>/</span> Chassi{" "}
                  <span>/</span> Plataforma
                </p>
              </S.Info>
              <S.Info>
                <p>
                  <span className="strong">**</span>
                  Taxa será acrescida no valor da compra. Taxa bancária no{" "}
                  <span>PIX</span> de <span>1,53%</span>. Taxa bancária no{" "}
                  <span>Boleto</span> no valor de <span>R$ 3,92</span>.
                </p>
              </S.Info>

              <Button data-variant-dark onClick={handleScheduling}>
                AGENDE SUA VISTORIA
              </Button>
            </S.ServiceInfos>
          </S.ServiceContent>
        </S.SectionService>
        <S.SectionLocalization id="localizacao">
          <S.LocalizationContent>
            <h2>
              <img src="/assets/imgs/group.svg" alt="" />
              Localização
            </h2>
            <S.WrapperMap>
              <img
                src="/assets/imgs/map1.svg"
                alt="mapa"
                onClick={() =>
                  window.open(
                    "https://www.google.com.br/maps/place/Velox+Vistorias+-+S%C3%A3o+Lu%C3%ADs/@-2.6067816,-44.2489653,17z/data=!4m6!3m5!1s0x7f69b6b48d0f5df:0xd7a295cac5e76fe1!8m2!3d-2.6064869!4d-44.2473238!16s%2Fg%2F11vpff36v5?entry=ttu",
                    "_black"
                  )
                }
              />
              <h5>
                <img src="/assets/imgs/pin.svg" alt="pin mapa" />
                SÃO LUÍS/ MA
              </h5>
            </S.WrapperMap>
            <S.WrapperMap>
              <img
                src="/assets/imgs/map2.svg"
                alt="mapa"
                onClick={() =>
                  window.open(
                    "https://www.google.com.br/maps/place/Vlx+vistorias+-+Imperatriz/@-5.506504,-47.4541218,17z/data=!4m6!3m5!1s0x92cf8bad96492407:0x290ed27bbb04f5d8!8m2!3d-5.5061783!4d-47.4531347!16s%2Fg%2F11vk_dxzb6?entry=ttu",
                    "_black"
                  )
                }
              />
              <h5>
                <img src="/assets/imgs/pin.svg" alt="pin mapa" />
                imperatriz/ MA
              </h5>
            </S.WrapperMap>
            <S.WrapperMap>
              <img
                src="/assets/imgs/map3.svg"
                alt="mapa"
                onClick={() =>
                  window.open(
                    "https://www.google.com.br/maps/place/Velox+Vistorias+-+Estreito/@-6.5568575,-47.4423991,17z/data=!3m1!4b1!4m6!3m5!1s0x92d0a176d9cd0931:0x1f45c866c790b3e4!8m2!3d-6.5568628!4d-47.4398242!16s%2Fg%2F11lcy4ghn8?entry=ttu",
                    "_black"
                  )
                }
              />
              <h5>
                <img src="/assets/imgs/pin.svg" alt="pin mapa" />
                estreito/ MA
              </h5>
            </S.WrapperMap>
            <S.WrapperMap>
              <img
                src="/assets/imgs/map4.svg"
                alt="mapa"
                onClick={() =>
                  window.open(
                    "https://www.google.com.br/maps/place/VLX+Vistorias+-+Bar%C3%A3o+de+Graja%C3%BA/@-5.8222625,-46.1489112,17z/data=!3m1!4b1!4m6!3m5!1s0x92d21f003a388eab:0x818b876dbbf41b24!8m2!3d-5.8222678!4d-46.1463363!16s%2Fg%2F11lcsz6pwq?entry=ttu",
                    "_black"
                  )
                }
              />
              <h5>
                <img src="/assets/imgs/pin.svg" alt="pin mapa" />
                grajaú/ MA
              </h5>
            </S.WrapperMap>
            <S.WrapperMap>
              <img
                src="/assets/imgs/map5.svg"
                alt="mapa"
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/place/Velox+Vistorias/@-4.5688377,-44.6111133,17z/data=!3m1!4b1!4m6!3m5!1s0x78a272083c28ca5:0x7477bda5cbfb66f3!8m2!3d-4.5688377!4d-44.6085384!16s%2Fg%2F11vprym05z?entry=ttu",
                    "_black"
                  )
                }
              />
              <h5>
                <img src="/assets/imgs/pin.svg" alt="pin mapa" />
                pedreiras/ MA
              </h5>
            </S.WrapperMap>
          </S.LocalizationContent>
        </S.SectionLocalization>
        <hr />
        <S.SectionContact id="contato">
          <S.ContactContent>
            <h2>
              <img src="/assets/imgs/group.svg" alt="icone group" />
              Contato
            </h2>

            <S.WrapperContacts>
              <S.Contact>
                <S.ContactText>
                  <p>imperatriz/ MA</p>
                </S.ContactText>
                <h3>
                  <img src="/assets/imgs/phone.svg" alt="icone telefone" />
                  (99) 9.8444-8605
                </h3>
              </S.Contact>
              <S.Contact>
                <S.ContactText>
                  <p>são luís / MA</p>
                  <p>grajaú / MA</p>
                  <p>estreito / MA</p>
                  <p>pedreiras / MA</p>
                </S.ContactText>
                <h3>
                  <img src="/assets/imgs/phone.svg" alt="icone telefone" />
                  (98) 9.9988-9623
                </h3>
              </S.Contact>
            </S.WrapperContacts>
          </S.ContactContent>
        </S.SectionContact>
      </S.Container>
    </LayoutTemplate>
  );
};
