import { useLocation, useNavigate } from "react-router-dom";
import { cleanStorage } from "../../../utils/cleanStorage";
import { RolesEnum } from "../../../enums/roles";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { useState } from "react";

export const useLayoutTemplate = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isHome = pathname === "/";
  const [isOpen, setIsOpen] = useState(false);
  const [token] = useSessionStorage("@token");
  const [cliente, setCliente] = useSessionStorage("cliente");

  const isAdmGerente = cliente?.role?.some(
    (regra) =>
      regra === RolesEnum.ROLE_ADMIN || regra === RolesEnum.ROLE_GERENTE
  );

  const isCliente = !!(
    cliente?.role?.includes(RolesEnum.ROLE_CLIENTE) && token
  );

  function handleHome() {
    if (isHome) return;
    window.open("/", "_self");
  }

  function handleCloseMenuMobile() {
    setIsOpen(false);
  }

  function handleLoginLogout() {
    if (token) {
      sessionStorage.removeItem("@token");
      cleanStorage();
      window.open("/", "_self");
      return;
    }

    window.open("/login-cadastro", "_self");
  }
  return {
    navigate,
    isCliente,
    handleLoginLogout,
    handleCloseMenuMobile,
    handleHome,
    isAdmGerente,
    isOpen,
    setIsOpen,
    isHome,
    token,
  };
};
