import * as S from "./styles";
import { HashLink } from "react-router-hash-link";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

import { useLayoutTemplate } from "./useLayoutTemplate";

export const LayoutTemplate = ({ children }: { children: React.ReactNode }) => {
  const {
    handleCloseMenuMobile,
    handleHome,
    handleLoginLogout,
    isAdmGerente,
    isCliente,
    isOpen,
    navigate,
    setIsOpen,
    isHome,
    token,
  } = useLayoutTemplate();

  return (
    <S.Container>
      <S.Main>
        {isOpen && (
          <S.NavMenuMobile>
            <div>
              <S.Logo
                src={
                  isHome
                    ? "/assets/imgs/logo-vlx-light.svg"
                    : "/assets/imgs/logo-vlx-dark.svg"
                }
                alt="logo vlx"
                onClick={handleHome}
              />
              <IoClose
                size={32}
                color={"white"}
                onClick={() => setIsOpen(false)}
              />
            </div>
            <S.MenuMobile>
              <S.MenuMobileItem>
                <HashLink
                  onClick={handleCloseMenuMobile}
                  smooth
                  to={"/login-registro"}
                >
                  <p> {token ? "LOGOUT" : "LOGIN"}</p>
                </HashLink>
              </S.MenuMobileItem>
              <S.MenuMobileItem>
                {" "}
                <HashLink
                  onClick={handleCloseMenuMobile}
                  smooth
                  to={isHome ? "#sobre" : "/#sobre"}
                >
                  <p>QUEM SOMOS</p>
                </HashLink>
              </S.MenuMobileItem>
              <S.MenuMobileItem>
                {" "}
                <HashLink
                  onClick={handleCloseMenuMobile}
                  smooth
                  to={isHome ? "#servicos" : "/#servicos"}
                >
                  <p>SERVIÇOS</p>
                </HashLink>
              </S.MenuMobileItem>
              <S.MenuMobileItem>
                {" "}
                <HashLink
                  onClick={handleCloseMenuMobile}
                  smooth
                  to={isHome ? "#localizacao" : "/#localizacao"}
                >
                  <p>LOCALIZAÇÃO</p>
                </HashLink>
              </S.MenuMobileItem>
              <S.MenuMobileItem>
                <HashLink
                  onClick={handleCloseMenuMobile}
                  smooth
                  to={isHome ? "#contato" : "/#contato"}
                >
                  <p>CONTATOS</p>
                </HashLink>
              </S.MenuMobileItem>

              <S.MenuMobileItem>
                <HashLink
                  onClick={handleCloseMenuMobile}
                  smooth
                  to={"/meus-agendamentos"}
                >
                  <p>Meus Agendamentos</p>
                </HashLink>
              </S.MenuMobileItem>
            </S.MenuMobile>
          </S.NavMenuMobile>
        )}

        <S.Header>
          <S.HeaderContent>
            <S.Logo
              src={
                isHome
                  ? "/assets/imgs/logo-vlx-light.svg"
                  : "/assets/imgs/logo-vlx-dark.svg"
              }
              alt="logo vlx"
              onClick={handleHome}
            />

            <S.Nav isHome={isHome}>
              <ul>
                <li>
                  <HashLink smooth to={isHome ? "#sobre" : "/#sobre"}>
                    quem somos
                  </HashLink>{" "}
                </li>
                <li>
                  <HashLink smooth to={isHome ? "#servicos" : "/#servicos"}>
                    Serviços
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    smooth
                    to={isHome ? "#localizacao" : "/#localizacao"}
                  >
                    Localização
                  </HashLink>{" "}
                </li>
                <li>
                  <HashLink smooth to={isHome ? "#contato" : "/#contato"}>
                    Contatos
                  </HashLink>
                </li>

                {isCliente && (
                  <li>
                    <Link className="buttonHome" to={"/meus-agendamentos"}>
                      Meus Agendamentos
                    </Link>
                  </li>
                )}
                {isAdmGerente && (
                  <li>
                    <Link className="buttonHome" to={"/meus-agendamentos"}>
                      Agendamentos
                    </Link>
                  </li>
                )}

                <li>
                  <button
                    type="button"
                    className="buttonHome buttonScheduling"
                    onClick={handleLoginLogout}
                  >
                    {token ? "LOGOUT" : "LOGIN"}
                  </button>{" "}
                </li>
              </ul>
            </S.Nav>
            <S.IconMenuMobile
              isHome={isHome}
              src="/assets/imgs/menu-mobile.svg"
              alt="icone menu mobile"
              onClick={() => setIsOpen(true)}
            />
          </S.HeaderContent>
        </S.Header>
        {children}
      </S.Main>
      <S.Footer>
        <S.FooterSection1>
          <S.Logo src="/assets/imgs/logo-vlx-dark.svg" alt="logo" />

          <S.FooterSectionContent>
            <div>
              <h4>Fale conosco</h4>
              <p>
                {" "}
                <img src="/assets/imgs/mail.svg" alt="icone de carta" />{" "}
                contato@veloxvistorias.com
              </p>
            </div>
            <div>
              <h4>Redes Sociais</h4>
              <S.WrapperSocial>
                <img
                  src="/assets/imgs/facebook.svg"
                  alt="icone de rede social"
                />
                <img
                  src="/assets/imgs/instagram.svg"
                  alt="icone de rede social"
                />
                <img
                  src="/assets/imgs/youtube.svg"
                  alt="icone de rede social"
                />
              </S.WrapperSocial>
            </div>
          </S.FooterSectionContent>
          <div>
            <h4>Confira os itens verificados</h4>
            <S.LinkDownDoc href="/assets/docs/checklist-vlx.pdf" download>
              <img
                src="/assets/imgs/icon-checklist.svg"
                alt="icone checklist"
              />
              <span>Baixar Checklist</span>
            </S.LinkDownDoc>
            {/* <h4>Redes Sociais</h4>
            <S.WrapperSocial>
              <img src="/assets/imgs/facebook.svg" alt="icone de rede social" />
              <img
                src="/assets/imgs/instagram.svg"
                alt="icone de rede social"
              />
              <img src="/assets/imgs/youtube.svg" alt="icone de rede social" />
            </S.WrapperSocial> */}
          </div>
        </S.FooterSection1>
        <S.FooterSection2>
          <p>Direitos Autorais © 2023 VLX VISTORIAS</p>
          <div className="wrapperButton">
            <button onClick={() => navigate("/termos-de-uso")}>
              Termos de Uso
            </button>
            <span>|</span>
            <button onClick={() => navigate("/politica-de-privacidade")}>
              Política de Privacidade
            </button>
          </div>
        </S.FooterSection2>
      </S.Footer>
    </S.Container>
  );
};
