import styled, { css } from "styled-components";

export const Container = styled.div<{ isHome?: boolean }>`
  ${({ theme: { space }, isHome }) => css`
    width: 100%;
    /* max-width: ${space.large}; */
    margin: 0 auto;

    .buttonHome {
      border-radius: 2px;
      border: 1px solid #eee;
      background: rgba(239, 239, 239, 0.3);
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;

      color: #efefef;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;

      font-family: "Open Sans";

      ${!isHome &&
      css`
        border-radius: 2px;
        border: 1px solid var(--VLX, #efefef);
        background: linear-gradient(180deg, #848484 -6.62%, #1c1c1c 100%);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      `}
    }

    .buttonScheduling {
      min-width: 160px;
      font-family: "Open Sans";
    }
  `}
`;

export const Header = styled.header`
  ${({ theme: { space } }) => css`
    width: 100%;
    margin: 0 auto;
    padding-top: 78px;
    padding-bottom: 20px;

    @media (max-width: 500px) {
      padding: 32px 20px;
    }
  `}
`;

export const Main = styled.main`
  background-color: #0c0c0c;
  background-color: #fff;
  width: 100%;
`;

export const HeaderContent = styled.header`
  ${({ theme: { space } }) => css`
    width: 100%;
    margin: 0 auto;
    max-width: ${space.medio};
    position: relative;
    z-index: 1;

    @media (max-width: 500px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `}
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto 60px;
  cursor: pointer;

  @media (max-width: 500px) {
    width: 136px;
    margin: 0;
  }
`;

export const Nav = styled.nav<{ isHome?: boolean }>`
  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > ul li a {
    color: ${(props) => (props.isHome ? "#efefef" : "#111")};
    font-family: "Syncopate";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
    text-transform: uppercase;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const IconMenuMobile = styled.img<{ isHome?: boolean }>`
  ${(props) =>
    !props.isHome &&
    css`
      filter: brightness(0) saturate(100%) invert(0%) sepia(100%)
        saturate(7431%) hue-rotate(231deg) brightness(109%) contrast(114%);
    `}

  @media (min-width: 501px) {
    display: none;
  }
`;

export const Footer = styled.footer`
  padding-bottom: 4px;
`;

export const FooterSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;

  @media (max-width: 500px) {
    margin-bottom: 32px;
  }
`;

export const FooterSection1 = styled.div`
  padding: 120px 0;
  display: flex;
  justify-content: center;
  gap: 0 100px;
  flex-wrap: wrap;
  align-items: center;

  h4 {
    color: #0c0c0c;
    margin-bottom: 20px;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    text-transform: uppercase;
  }

  p {
    color: #0c0c0c;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    gap: 0 6px;
    align-items: center;
    line-height: 24px;
  }

  > :nth-child(1) {
    margin: 0;
  }

  > :nth-child(2) {
    width: 300px;
  }
  > :nth-child(3) {
    width: 300px;
  }

  @media (max-width: 500px) {
    padding: 32px 20px;

    > :nth-child(1) {
      margin: 0 0 32px;
    }

    h4 {
      font-size: 20px;
      text-align: center;
    }

    p {
      font-size: 16px;
      text-align: center;
      margin-bottom: 24px;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const FooterSection2 = styled.div`
  background-color: #0c0c0c;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 20px;
  flex-wrap: wrap;

  div.wrapperButton {
    display: flex;
    align-items: center;
    gap: 0 6px;
    color: #efefef;
  }

  button {
    all: unset;
    cursor: pointer;
  }

  p,
  button {
    color: #efefef;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  @media (max-width: 500px) {
    gap: 0;
    flex-direction: column;
    line-height: 24px;
    padding: 20px 0;
    height: fit-content;
  }
`;

export const WrapperSocial = styled.div`
  display: flex;
  gap: 0 24px;
  align-items: center;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const NavMenuMobile = styled.nav`
  padding: 40px 20px;
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #0c0c0c;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  @media (min-width: 501px) {
    display: none;
  }
`;

export const MenuMobile = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 40px 0 0;

  li + li {
    border-top: 1px solid #efefef;
  }
`;

export const MenuMobileItem = styled.li`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-family: "Open Sans";
    color: #efefef;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
  }
`;

export const LinkDownDoc = styled.a`
  display: flex;
  gap: 0 8px;
  align-items: center;

  > span {
    color: var(--VLX-CINZA, #0c0c0c);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    text-decoration-line: underline;
  }

  @media (max-width: 500px) {
    justify-content: center;
  }
`;
