import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;

  margin: 0 auto;

  > hr {
    height: 20px;
    background-color: #efefef;
  }
`;

export const Main = styled.main`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.large};
    margin: 0 auto;

    height: 934px;
    background-image: url("/assets/imgs/img-bg-home.svg");
    margin-top: -300px;
    padding-top: 420px;
    background-repeat: no-repeat;
    background-size: cover;

    > button {
      margin: 0 auto;
    }

    @media (max-width: 500px) {
      padding-top: 340px;
      border: 1px solid red;
      height: 400px;
      margin-top: -104px;
      background-size: cover;
      padding-top: 80px;
      background-image: url("/assets/imgs/img-bg-home-mobile.svg");
    }
  `}
`;

export const Title = styled.h1`
  color: #efefef;
  text-align: center;
  font-family: "Syncopate";
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: 80px;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  img {
    margin-right: -20px;
    margin-bottom: -10px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
    margin-bottom: 15px;

    > img {
      width: 15px;
      margin-right: -6px;
      margin-bottom: -6px;
    }
  }
`;

export const Text = styled.p`
  color: #efefef;
  text-align: center;
  max-width: 922px;
  font-size: 20px;
  margin: 0 auto 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  @media (max-width: 500px) {
    font-size: 16px;
    max-width: 312px;
    margin: 0 auto 32px;

    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const Strong = styled.span`
  font-weight: 700;
`;

export const SectionService = styled.section`
  ${({ theme: { space } }) => css`
    width: 100%;
    margin: 0 auto;
    background-color: #efefef;
  `}
`;

export const ServiceContent = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.medio};
    margin: 0 auto;
    padding: 120px 0;

    > h2 {
      color: #0c0c0c;
      text-align: center;
      font-family: "Syncopate";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 80px;
      text-transform: uppercase;
      margin-bottom: 16px;

      > img {
        width: 30px;
      }
    }

    > p {
      color: #0c0c0c;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 120px;
    }

    @media (max-width: 500px) {
      padding: 32px 20px;

      > h2 {
        font-size: 20px;
        margin-bottom: 16px;
        line-height: 32px;

        img {
          width: 15px;
        }
      }

      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        max-width: 205px;
        margin: 0 auto 40px;
      }
    }
  `}
`;

export const ServiceInfos = styled.div`
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;

  #FirtItem {
    margin-right: auto;
    margin-left: 86px;
  }

  > button {
    margin: 80px auto 0;
  }

  > h2 {
    color: #0c0c0c;
    font-family: "Syncopate";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    max-width: 952px;
    margin-bottom: 40px;
    margin-top: 80px;
  }

  @media (max-width: 500px) {
    > button {
      margin: 24px auto 0;
    }

    > h2 {
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.32px;
      margin-bottom: 24px;
      margin-top: 0;

      margin-top: 32px;
    }

    #FirtItem {
      margin-left: 32px;
    }
  }
`;

export const Wrapper = styled.div`
  border-radius: 2px;
  background: #d9d9d9;
  min-height: 72px;
  display: flex;

  h4 {
    color: #0c0c0c;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    position: relative;

    span {
      color: #ed0000;
      font-family: "Space Grotesk";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      position: absolute;
      top: 0;
    }
  }

  h3 {
    color: #0c0c0c;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    position: relative;

    span {
      color: #ed0000;
      font-size: 16px;
      position: absolute;
      top: -5px;
    }
  }

  > div {
    padding: 25px 0;
  }

  > :nth-child(1) {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > :nth-child(2) {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    > h4 {
      margin-left: 70px;
    }
  }

  :nth-child(1) + :nth-child(2) {
    border-left: 1px solid #111111;
  }

  & + div {
    margin-top: 16px;
  }

  @media (max-width: 500px) {
    p {
      color: #2d2d2d;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 10px 20px;
      display: flex;
      justify-content: center;

      span {
        position: relative;
        align-self: flex-start;
        display: inline-block;
      }
    }

    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }

    > :nth-child(1) {
      flex: 2;
    }

    > :nth-child(2) {
      > h4 {
        padding: 0 20px;
        margin-left: 0;
      }
    }
  }
`;

export const Info = styled.div`
  border-radius: 2px;
  background: #d9d9d9;
  min-height: 72px;
  padding: 6px 12px;
  display: flex;
  gap: 0 4px;
  justify-content: center;

  > p span.strong {
    color: #ed0000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    padding-right: 4px;
  }

  > p {
    color: #2d2d2d;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    span {
      color: #0c0c0c;
      font-weight: 800;
    }
  }

  & + div {
    margin-top: 16px;
  }

  @media (max-width: 500px) {
    gap: 0;

    > p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

export const SectionLocalization = styled.section`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.large};
    margin: 0 auto;
    background-color: #0c0c0c;
  `}
`;

export const LocalizationContent = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.medio};
    margin: 0 auto;
    padding: 120px 0;

    h2 {
      color: #efefef;
      text-align: center;
      font-family: "Syncopate";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 80px;
      text-transform: uppercase;
      margin-bottom: 40px;

      img {
        width: 30px;
      }
    }

    @media (max-width: 500px) {
      padding: 32px 20px;

      h2 {
        text-align: center;
        font-family: Syncopate;
        font-size: 20px;
        margin-bottom: 16px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;

        img {
          width: 15px;
        }
      }
    }
  `}
`;

export const WrapperMap = styled.div`
  & + div {
    margin-top: 90px;
  }

  > img {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  h5 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    text-transform: uppercase;
    display: flex;
    gap: 0 14px;
    justify-content: end;
    margin-top: 40px;
  }

  @media (max-width: 500px) {
    & + div {
      margin-top: 18px;
    }

    h5 {
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 20px;
      margin-top: 14px;
      gap: 0 4px;

      > img {
        width: 12px;
      }
    }
  }
`;

export const SectionContact = styled.section`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.large};
    margin: 18px auto 0;

    background-color: #0c0c0c;
  `}
`;

export const ContactContent = styled.div`
  ${({ theme: { space } }) => css`
    width: 100%;
    max-width: ${space.medio};
    margin: 0 auto;
    padding: 80px 0;

    
    h2 {
        color: #efefef;
        text-align: center;
        font-family: "Syncopate";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 80px;
        text-transform: uppercase;
        margin-bottom: 50px;

        img {
          width: 30px;
        }
      }  
      
      
      @media (max-width: 500px) {
      padding: 32px 20px;

      h2 {
        text-align: center;
        font-family: Syncopate;
        font-size: 20px;
        margin-bottom: 24px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;

        img {
          width: 15px;
        }
      }
    }
    }
  `}
`;

export const WrapperContacts = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 120px;
  flex-wrap: wrap;
  align-items: end;
`;

export const ContactText = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 420px;
  gap: 0 32px;
  justify-content: center;
  margin-bottom: 32px;

  p {
    color: #efefef;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  @media (max-width: 500px) {
    margin-bottom: 16px;

    flex-direction: column;

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #efefef;
      letter-spacing: 0.6px;
    }
  }
`;

export const Contact = styled.div`
  > h3 {
    color: #efefef;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    display: flex;
    align-items: center;
    gap: 0 12px;
    justify-content: center;
  }

  @media (max-width: 500px) {
    > h3 {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 24px;

      img {
        width: 20px;
      }
    }
  }
`;
