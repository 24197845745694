import { LayoutTemplate } from "../LayoutTemplate";
import * as S from "./styles";

export const TermsOfUseTemplate = () => {
  return (
    <LayoutTemplate>
      <S.Container>
        <S.Content>
          <h1>POR FAVOR LEIA OS SEGUINTES TERMOS E CONDIÇÕES CUIDADOSAMENTE</h1>

          <div>
            <p className="title-section">1. Identificação</p>
            <p>{`Este site é de propriedade, mantido e operado pelo VELOX VISTORIAS LTDA, com sede na VIA DE ACESSO A BR-135 – AV. EMILIANO CERQUEIRA, S/N – SALA 07 – VILA MARANHÃO SÃO LUÍS – MA CEP: 65.091-320, inscrita no CNPJ sob nº 51.372.672/0001-69. Nessa Política sendo referenciada somente como VELOX VISTORIAS LTDA.`}</p>
            <p className="title-section">
              2. Contato - CAC (Central de Atendimento ao Cliente)
            </p>
            <p>{`Caso o Usuário necessite de qualquer informação, esclarecimento ou atendimento com relação a esta Política de Privacidade e Segurança, a VELOX VISTORIAS LTDA disponibiliza o CAC para receber todas as comunicações que o Usuário desejar fazer. O CAC opera por meios dos canais de comunicação listados abaixo:`}</p>
            <ul>
              <li>{`Presencialmente, em qualquer unidade da VELOX VISTORIAS LTDA, durante o horário de funcionamento.`}</li>
              <li>{`Pelos telefones (99) 98444-8605, durante o horário de funcionamento da sede.`}</li>
              <li>{`Por correspondência endereçada à VELOX VISTORIAS LTDA.`}</li>
              <li>{`Através do e-mail OUVIDORIA@LOGVISTORIAS.COM.`}</li>
            </ul>
            <p>{`Em todos os casos, o Usuário receberá, uma confirmação do recebimento da sua demanda, que será tratada e respondida em um prazo máximo de 5 (cinco) dias. Além disso, a VELOX VISTORIAS LTDA disponibiliza o Termo de Uso do site. Todos os documentos podem ser consultados online ou baixados pelo Usuário em formato PDF para consulta off-line.`}</p>
            <p className="title-section">3. Informações Gerais</p>
            <p>{`A VELOX VISTORIAS LTDA toma todas as medidas necessárias para proteger a privacidade do Usuário, em atendimento à legislação em vigor. Este documento detalha em formas de coleta, guarda, utilização, compartilhamento e divulgação de seus dados pessoais, bem como aponta claramente as medidas tomadas para assegurar a proteção dos dados coletados.`}</p>
            <p className="title-section">
              4. Coleta, utilização e guarda dos dados
            </p>
            <p>{`Coleta de dados: Formulário de Cadastro - Para cadastro no sistema de agendamento online, o Usuário deve preencher o formulário com seus dados pessoais e de contato. Desta forma, os dados de nome completo, telefone e e-mail e endereço são requeridos para que a VELOX VISTORIAS LTDA possa entrar em contato e/ou informar sobre atualizações no processo de agendamento de vistorias. Os dados fornecidos pelo Usuário serão usados pela VELOX VISTORIAS LTDA ou terceiros por ela contratados para realizar o processo de contratação do serviço de vistoria e posterior agendamento do dia e horário de realização validando as informações apresentadas no momento da realização presencial do serviço.`}</p>
            <p>{`Coleta de dados: Formulário de Consulta do Veículo - Para consultar as informações do veículo na base nacional do Detran será solicitado o preenchimento da placa e o RENAVAM ou chassi do veículo no momento do agendamento da vistoria. Os dados são requeridos para que a VELOX VISTORIAS LTDA possa consultar as demais informações do veículo para o preenchimento automático do cadastro do veículo em sistema e também para validar a situação legal do veículo.  Os dados serão mantidos na base de dados da VELOX VISTORIAS LTDA por no máximo 5 (cinco) anos ou até que o Usuário solicite a sua exclusão da base.`}</p>
            <p>
              {`Coleta de dados: Formulário de Reembolso – Para realizar o reembolso de pagamentos realizados em via boleto será solicitada as informações bancárias do usuário. Os dados são requeridos para que a VELOX VISTORIAS LTDA possa realizar a transferência bancaria, seja por PIX ou TED, para a conta do cliente após a compensação do boleto caso o cliente solicite o reembolso.`}
            </p>
            <p>
              {`Os dados poderão ser compartilhados com terceiros contratados pela VELOX VISTORIAS LTDA nos termos desta Política, ressaltando-se que o preenchimento dos formulários ou qualquer cadastro do Site, para o qual seja necessário o envio de qualquer dado pessoal do Usuário, pressupõe o consentimento expresso quanto à coleta, uso, armazenamento e tratamento dos dados pessoais.`}
            </p>
            <p>
              {`O Usuário é responsável, nas esferas civil e criminal, pela veracidade e atualização dos dados fornecidos (inclusive os dados pessoais) e a VELOX VISTORIAS LTDA se exime de qualquer responsabilidade por danos decorrentes do preenchimento incorreto, impreciso ou inexato dos dados cadastrados pelo Usuário, ou ainda pelo uso desses dados de forma indevida por qualquer terceiro que tenha, devida ou indevidamente, obtido os dados do Usuário agindo como se ele fosse.`}
            </p>
            <p>
              {`Coleta de dados: Cookies - Visando oferecer a melhor experiência de navegação ao Usuário, a VELOX VISTORIAS LTDA utiliza-se de tecnologias para coletar e armazenar informações relacionadas à visita do Usuário no Site e isso pode incluir o envio de um ou mais cookies ou identificadores anônimos que coletam dados relativos às preferências de navegação e às páginas visitadas pelo Usuário. Desta Forma, a apresentação do Site fica personalizada e alinhada aos interesses pessoais do Usuário.`}
            </p>
            <p>
              {`A utilização destes dados fica restrita ao objetivo indicado e a VELOX VISTORIAS LTDA se compromete a não utilizar ou permitir a utilização de tais dados com outra finalidade. Ademais, a coleta, guarda e tratamento destes dados é absolutamente automatizada, não havendo nenhuma possibilidade de contato humano com os dados em questão.`}
            </p>
            <p>
              {`O Usuário poderá, a qualquer tempo, caso discorde da política de cookies, utilizar as ferramentas de seu navegador que impedem a instalação de cookies e ainda apagar quaisquer cookies existentes em seu dispositivo de conexão com a internet. Neste caso, algumas funcionalidades do Site poderão apresentar erros. A VELOX VISTORIAS LTDA poderá ainda utilizar-se de outras tecnologias para coleta de dados de navegação do Usuário, comprometendo-se a guardá-los, tratá-los e utilizá-los em conformidade com esta Política.`}
            </p>
            <p>
              {`Coleta de dados: Registros de acesso - A VELOX VISTORIAS LTDA manterá em sua base de dados todas as informações relativas aos acessos do Usuário ao Site, incluindo, mas não se limitando ao endereço IP, às páginas acessadas, aos horários e datas de acesso e o dispositivo de acesso utilizado, nos termos da legislação vigente. Tais registros poderão ser utilizados em investigações internas ou públicas para averiguação de práticas que possam gerar quaisquer prejuízos à VELOX VISTORIAS LTDA, inclusive a prática de crimes em ambientes virtuais.`}
            </p>
            <p>
              {`Finalmente, a VELOX VISTORIAS LTDA poderá acessar bases de dados públicas ou privadas para confirmar a veracidade dos dados pessoais informados pelo Usuário, inclusive dados relacionados ao pagamento da compra.`}
            </p>
            <p>
              {`Utilização de dados: E-mail - A VELOX VISTORIAS LTDA utilizará o e-mail do Usuário prioritariamente para enviar informações sobre o processo de agendamento e/ou para envio de outros informativos.`}
            </p>
            <p>
              {`Utilização de dados: Telefone - A VELOX VISTORIAS LTDA utilizará o telefone do Usuário prioritariamente para enviar informações sobre o processo de agendamento (se for o caso).`}
            </p>
            <p>
              {`Utilização dos dados: Outras formas - Além das formas expostas acima, a VELOX VISTORIAS LTDA poderá, a seu exclusivo critério, utilizar os dados pessoais do Usuário nas seguintes formas: (i) atualização de cadastro; (ii) garantia da segurança do usuário; (iii) resposta a solicitações do próprio Usuário; (iv) informação acerca de alterações nos Termos e Condições de Uso ou das Políticas; (v) elaboração de estatísticas com relação ao uso do Site, garantindo o anonimato do usuário, inclusive para fins de aperfeiçoamento e entendimento do perfil dos Usuários para melhoria do Site; (vi) aperfeiçoamento de ferramentas de interatividade entre o Site e o usuário, garantindo o seu anonimato; (vii) cumprimento de ordens judiciais; e (vii) defesa dos direitos da VELOX VISTORIAS LTDA contra o Usuário em procedimentos judiciais ou administrativos.`}
            </p>
            <p>
              {`Guarda dos dados: A VELOX VISTORIAS LTDA guardará todos os dados coletados em suas bases de dados protegidas e seguras. Tais dados serão acessados apenas por processos computadorizados automatizados, profissionais autorizados e nos casos listados nesta política. Caso o Usuário requeira a exclusão de seus dados da base de dados, a VELOX VISTORIAS LTDA se reserva ao seu direito de manter os dados em questão em cópias de salvaguarda por até 6 meses, a fim de cumprir obrigações legais de guarda obrigatória.`}
            </p>
            <p className="title-section">{`5. Compartilhamento e divulgação dos dados`}</p>
            <p>{`A VELOX VISTORIAS LTDA tem a confidencialidade dos dados pessoais do Usuário como prioridade. Assim, assume o compromisso de não divulgar, compartilhar, dar acesso, facilitar acesso, alugar, vender, trocar ou de qualquer outra forma disponibilizar tais informações a terceiros, sob nenhum pretexto, exceto nos casos autorizados expressamente pelo Usuário, inclusive nos casos indicados abaixo.`}</p>
            <p>{`Com o único intuito de permitir a concretização de doações pelo site, a VELOX VISTORIAS LTDA poderá compartilhar dados pessoais dos Usuários com empresas processadoras de pagamentos. Neste caso, serão compartilhados apenas dados imprescindíveis para que o parceiro desempenhe a sua atividade. Ademais, tais parceiros serão obrigados, por meio de contratos de confidencialidade, a não arquivar, manter em arquivo, compilar, copiar, reproduzir ou compartilhar tais dados com quem quer que seja. A outra hipótese de divulgação dos dados é por meio de determinação judicial. Também neste caso, a divulgação ocorrerá apenas na medida necessária para cumprir a determinação judicial, permanecendo sigilosos os dados não requeridos pela autoridade em questão.`}</p>
            <p className="title-section">{`6. Dados transmitidos sem solicitação da VELOX VISTORIAS LTDA`}</p>
            <p>{`A VELOX VISTORIAS LTDA solicita ao Usuário que não envie à VELOX VISTORIAS LTDA quaisquer informações comerciais, criações pessoais, ideias, fotografias, projetos, conceitos etc. (Conteúdos Não Solicitados). Tais conteúdos serão sumariamente descartados, sem qualquer leitura ou incorporação às bases de dados da VELOX VISTORIAS LTDA. Nos termos da Lei de Direitos Autorais, não são suscetíveis de proteção no Brasil as ideias, concepções abstratas, projetos, planos e esquemas. Destra Forma, o eventual uso pela VELOX VISTORIAS LTDA de quaisquer Conteúdo Não Solicitado será decorrente de desenvolvimento interno e independente e poderá ocorrer livremente, não sendo devida ou exigida qualquer autorização ou compreensão ao usuário ou consumidor. A VELOX VISTORIAS LTDA desenvolve de forma independente todas as suas políticas e atividades, rechaçando desde já qualquer acusação ou alegação de aproveitamento de Conteúdos Não Solicitados.`}</p>
            <p className="title-section">{`7. Medidas de segurança`}</p>
            <p>{`Recursos tecnológicos. A VELOX VISTORIAS LTDA adota recursos tecnológicos avançados para garantir a segurança de todos os dados pessoais coletados e armazenados. Nem mesmo os funcionários da VELOX VISTORIAS LTDA têm livre acesso à base de dados dos usuários, sendo este limitado apenas àquelas pessoas cujas funções exigem o contato com os dados. Entre as medidas de segurança implementadas estão a utilização de modernas formas de criptografia e a instalação de barreiras contra o acesso indevido à base de dados (firewalls). Tais medidas podem ser verificadas pelo Usuário acessando o Site pela visualização do "cadeado de segurança" em seu navegador de internet.`}</p>
            <p>{`E-mails suspeitos. A VELOX VISTORIAS LTDA envia ao Usuário apenas e-mails com as finalidades especificadas no artigo 4. A VELOX VISTORIAS LTDA não envia mensagens (i) solicitando dados pessoais do usuário; (ii) solicitando a senha ou dados financeiros do Usuário; (iii) ou com arquivos anexos exceto documentos em PDF. Caso receba um e-mail com tais características, desconsidere-o e entre em contato com o CAC.`}</p>

            <p>{`E-mails suspeitos. A VELOX VISTORIAS LTDA envia ao Usuário apenas e-mails com as finalidades especificadas no artigo 4. A VELOX VISTORIAS LTDA não envia mensagens (i) solicitando dados pessoais do usuário; (ii) solicitando a senha ou dados financeiros do Usuário; (iii) ou com arquivos anexos exceto documentos em PDF. Caso receba um e-mail com tais características, desconsidere-o e entre em contato com o CAC.`}</p>
            <p>{`Cartões de crédito. A VELOX VISTORIAS LTDA não armazena em sua base de dados informações financeiras do Usuário, como as informações referentes a cartões de crédito. O procedimento de aprovação do pagamento ocorre entre o Usuário, a processadora de pagamentos, os bancos e as administradoras de cartões, sem intervenção da VELOX VISTORIAS LTDA.`}</p>
            <p className="title-section">{`8. Direitos das pessoas dobre os dados coletados`}</p>
            <p>{`A VELOX VISTORIAS LTDA permite que o Usuário faça diferentes preenchimentos de formulários com dados diferentes. Assim, o Usuário tem a possibilidade de alterar os dados cadastrados, devendo preenchê-lo com informações verídicas e atualizadas. O Usuário declara ser o legitimo titular de seus dados pessoais e poderá, a qualquer momento, atualizá-los ou removê-los preventivamente de nossa base de dados. A VELOX VISTORIAS LTDA manterá os dados removidos em sigilo pelo prazo de 6 (seis) meses, para atender obrigações legais, descartando-os definitivamente após tal período.`}</p>
            <p className="title-section">{`9. Uso de dados em caso de alteração de controle da VELOX VISTORIAS LTDA`}</p>
            <p>{`Os dados coletados podem ser eventualmente transferidos a um terceiro em caso de alteração do controle, de uma aquisição, de uma incorporação ou de uma fusão da VELOX VISTORIAS LTDA sob qualquer meio ou forma.`}</p>
            <p className="title-section">{`10. Política de dados de menores`}</p>
            <p>{`O Site não é direcionado a menores de 18 (dezoito) anos. No entanto, o acesso ao Site não é proibido aos menores, uma vez que não há qualquer conteúdo restrito por questões etárias. Os formulários do Site não visam obter dados de menores. Caso tais dados sejam inseridos por menores, seu representante legal poderá contactar o CAC para retificar, modificar ou remover tais dados.`}</p>
          </div>
        </S.Content>
      </S.Container>
    </LayoutTemplate>
  );
};
