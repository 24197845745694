import styled, { css } from "styled-components";

export const ButtonLogin = styled.button<{ $disabled?: boolean }>`
  ${({ $disabled }) => css`
    cursor: ${$disabled ? "not-allowed" : "pointer"};

    display: block;
    border-radius: 2px;
    border: 1px solid var(--VLX, #efefef);
    background: linear-gradient(180deg, #848484 -6.62%, #1c1c1c 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 60px;
    padding: 0 40px;
    min-width: 245px;

    color: #efefef;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;

    &[data-variant-dark="true"] {
      background: linear-gradient(180deg, #575757 0%, #0c0c0c 100%);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      min-width: 200px;
      border: NONE;
    }

    &[data-variant-ghost="true"] {
      color: #717171;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.54px;
      text-transform: uppercase;
      white-space: nowrap;

      border: none;
      min-width: fit-content;
      padding: 5px 10px;
      height: fit-content;
      background: transparent;
      box-shadow: none;
    }

    @media (max-width: 500px) {
      min-width: 200px;
      padding: 0 10px;
      font-size: 16px;

      &[data-variant-dark="true"] {
        min-width: 130px;
      }

      &[data-variant-ghost="true"] {
        font-size: 16px;
        padding: 0h;
      }
    }
  `}
`;
